<template>
    <div>
        <div class="header">CityBanana</div>
        <div class="container">
            <div>
                <div class="title">本月統計資訊</div>
                <div class="card">
                    <div class="val-title">累計分潤 <span>/</span> 預期分潤</div>
                    <div class="val orange-title">
                        ${{ statistics.final_profit | formatCurrency }}
                        <span> / </span>
                        ${{ statistics.expected_profit | formatCurrency }}
                    </div>
                </div>
                <div class="item-group">
                    <div class="card">
                        <div class="val-title">服務商累計營收</div>
                        <div class="val orange-title">${{ statistics.final_revenue | formatCurrency }}</div>
                    </div>
                    <div class="card">
                        <div class="val-title">警示服務商</div>
                        <div class="val orange-title">
                            {{ statistics.unattained_provider }}
                            <span>位</span>
                        </div>
                    </div>
                    <div class="card">
                        <div class="val-title">上線服務商</div>
                        <div class="val orange-title">
                            {{ statistics.active_provider }}
                            <span>位</span>
                        </div>
                    </div>
                    <div class="card">
                        <div class="val-title">總服務商</div>
                        <div class="val orange-title">
                            {{ statistics.total_provider }}
                            <span>位</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="news">
                <div class="title">最新消息</div>
                <ul>
                    <li v-for="(item, index) in news" :key="index" class="item">
                        <h5 class="text-sm">
                            {{ item.text }}
                            <a v-if="!$isEmpty(item.url)" :href="item.url" class="text-orange-600 underline" target="news">看更多</a>
                        </h5>
                        <p class="text-gray-700 text-xs">{{ !$isEmpty(item.start_date) ? item.start_date : item.created_at | formatDate }}</p>
                    </li>
                </ul>
            </div>
            <button class="btn-detail" @click="goProviderDetail">
                平台規範守則（務必詳閱！！！） <font-awesome-icon class="text-sm" :icon="['fas', 'angle-right']" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "PermissionRouteList",
    computed: {
        env() {
            return process.env;
        },
    },
    data() {
        return {
            // 列表資料
            loading: false,
            // 最新消息
            news: [],
            // 統計資料
            statistics: {},
        };
    },
    methods: {
        async getList() {
            try {
                const { data } = await this.$api.GetNewsApi();
                this.news = data.news;
            } catch (err) {
                this.errorCallback({ err });
            }
        },
        // 前往教戰守則網頁
        goProviderDetail() {
            window.open("https://blog.citybanana.com/?page_id=3944");
        },
        async getBrokerPerformanceStatistics() {
            try {
                const { data } = await this.$api.GetBrokerPerformanceStatistics();
                this.statistics = { ...data };
            } catch (err) {
                this.errorCallback({ err });
            }
        },
    },
    mounted() {
        this.getList();
        this.getBrokerPerformanceStatistics();
    },
};
</script>

<style lang="scss" scoped>
.header {
    width: 100%;
    height: 50px;
    display: flex;
    background: #fff;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 0;
    left: 0;
}
.orange-title {
    color: #ff5733;
    font-size: 24px;
    font-weight: 700;
}
.container {
    width: 100%;
    max-width: 600px;
    padding: 50px 15px 70px;
    .title {
        font-size: 16px;
        font-weight: 700;
        margin: 15px 0;
    }
    .card {
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        .val-title {
            font-size: 16px;
            font-weight: 700;
        }
        .val {
            display: flex;
            align-items: flex-end;
            span {
                font-size: 12px;
            }
        }
        span {
            margin: 0 5px;
            font-size: 16px;
            color: black;
        }
    }
    .item-group {
        max-width: 100%;
        margin-top: 15px;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr 1fr;
    }
    .news {
        margin-top: 24px;
        .title {
            border-bottom: #ff5733 1px solid;
            padding-bottom: 8px;
            margin-bottom: 0;
        }
        .item {
            padding: 10px 0;
            border-bottom: 1px solid #ccc;
            p {
                margin-top: 10px;
                color: #757575;
            }
        }
        .underline {
            text-decoration-line: underline;
        }
    }
    .btn-detail {
        width: 100%;
        background: linear-gradient(45deg, #ff5733, #fb3c9c);
        padding: 12px 20px;
        border-radius: 5px;
        text-align: left;
        color: #fff;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin: 50px 0;
    }
}
@media only screen and (max-width: 600px) {
    .orange-title {
        font-size: 16px;
    }
    .container {
        padding: 50px 15px 70px;
        .card {
            gap: 12px;
            .val-title {
                font-size: 16px;
            }
            span {
                margin: 0 4px;
            }
        }
    }
}
</style>
